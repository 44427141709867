import { createApp } from 'vue'
import './css/style.css'
import './css/tailwind.css'
import App from './App.vue'
import router from './router.js'

createApp(App).use(router).mount('#app');

console.log(
  `⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣠⣴⣶⣿⣿⣿⣷⣶⣤⣀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠶⠿⡿⠛⠋⠉⠉⠉⠉⠛⢿⣿⣷⣄⠀⠀⠀⠀⠀
⠀⠀⠀⠀⢀⣠⣴⣶⣾⣿⣿⣶⣦⣄⠀⠀⠀⠀⠀⠀⠀⠀⠈⢿⣿⣷⠀⠀⠀⠀
⠀⠀⠀⣴⣿⣿⡿⠟⠛⠛⠛⠛⢿⣿⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⡇⠀⠀⠀
⠀⠀⣼⣿⡿⠁⠀⠀⠀⢹⣿⣷⣄⠙⠿⡇⠀⠀⠀⠀⠀⠀⠀⠀⣸⣿⡇⠀⠀⠀
⠀⢸⣿⡿⠁⠀⠀⠀⠀⠀⠻⢿⣿⣷⣦⣄⣀⠀⠀⠀⠀⠀⠀⢀⣿⣿⠇⠀⠀⠀
⠀⣾⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠿⣿⣿⣿⣷⣶⣶⡄⢀⣾⣿⡿⢀⣀⡀⠀
⠀⢿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⣼⣷⡦⠀⠈⠉⠛⠛⠟⢠⣾⣿⡟⠁⣾⣿⡷⠀
⠀⠸⣿⣿⣄⠀⠀⠀⠀⠀⠀⢰⣿⣿⠃⠀⠀⠀⠀⢀⣴⣿⣿⠋⠀⠀⠀⠀⠀⠀
⠀⠀⠙⢿⣿⣷⣦⣄⣀⠀⢀⣾⣿⠇⠀⣀⣠⣤⣾⣿⡿⠛⠁⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠙⠻⠿⣿⡟⢀⣾⣿⠏⢠⣿⣿⣿⡿⠟⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⢀⣾⣿⠏⠀⠉⠉⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⢿⣿⠏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀`,
  "color: #795bb0;"
);
