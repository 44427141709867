<script>
  import { nextArt, updateArtDisplay } from "/src/scripts/artDisplay.js";

  export default {
    mounted() {
      document.getElementById("next-art-button").addEventListener("click", nextArt);
      updateArtDisplay();
    },
  };
</script>
<template>
</template>
