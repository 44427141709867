<template>
  <div id="header">
    <header class="py-4 px-8 flex flex-col sm:flex-row justify-between items-center max-w-6xl mx-auto">
      <div class="text-center sm:text-left mb-4 sm:mb-0 w-full sm:w-auto">
        <router-link to="/extra/index" class="">
          <h1 class="text-7xl font-bold text-purple-500 text-center py-1 hover:text-purple-400 hover:text-shadow transition-all duration-300">SamJ</h1>
        </router-link>
      </div>
    </header>
  </div>
</template>
